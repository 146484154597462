@import './shared/constants/breakpoints';

:root {
  --color-base-dark-blue: #12121c;
  --color-base-dark-blue-light: #1b1d2e;
  --color-base-dark-white: #e8e8ec;
  --color-base-saffron: #f1c408;
  --color-base-yellow-orange: #f5b105;
  --color-base-yellow-pressed: #d99e0a;
  --color-base-deep-orange: #f38125;
  --color-base-carrot: #f46623;
  --color-base-amaranth: #f1364d;
  --color-base-alizarin-crimson: #dc1d39;
  --color-base-dark-orchid: #9544bf;
  --color-base-purple: #5b39b6;
  --color-base-purplish-blue: #3c33bb;
  --color-base-jeans: #1e7dcb;
  --color-base-pacific-ocean: #2aa7d0;
  --color-base-forest-green: #1d9a19;
  --color-base-acid-green: #59be21;
  --color-white-100: #fff;
  --color-white-90: #ffffffe6;
  --color-white-80: #fffc;
  --color-white-70: #ffffffb3;
  --color-white-60: #fff9;
  --color-white-50: #ffffff80;
  --color-white-40: #fff6;
  --color-white-30: #ffffff4d;
  --color-white-20: #fff3;
  --color-white-10: #ffffff1a;
  --color-white-5: rgb(255 255 255 / 5%);
  --color-dark-100: #000;
  --color-dark-90: #000000e6;
  --color-dark-80: #000c;
  --color-dark-70: #000000b3;
  --color-dark-60: #0009;
  --color-dark-50: #00000080;
  --color-dark-40: #0006;
  --color-dark-30: #0000004d;
  --color-dark-20: #0003;
  --color-dark-10: #0000001a;
  --color-dark-5: rgb(0 0 0 / 5%);
  --spacing-xs: 5px;
  --spacing-s: 10px;
  --spacing-m: 15px;
  --spacing-l: 20px;
  --spacing-xl: 30px;
  --spacing-2xl: 45px;
  --spacing-3xl: 60px;
  --spacing-4xl: 80px;
  --spacing-5xl: 100px;
  --font-size-10: 10px;
  --font-size-11: 11px;
  --font-size-13: 13px;
  --font-size-15: 15px;
  --font-size-20: 20px;
  --font-size-24: 24px;
  --font-size-35: 35px;
  --line-height-45: 45px;
  --line-height-25: 25px;
  --line-height-20: 20px;
  --line-height-15: 15px;
  --letter-spacing-micro: -1px;
  --letter-spacing-mini: -0.5px;
  --letter-spacing-small: -0.3px;
  --letter-spacing-zero: 0;
  --letter-spacing-medium: 1.3px;
  --letter-spacing-large: 1px;
  --font-weight-350: 350;
  --border-radius-5: 5px;
  --border-radius-3: 3px;
  --border-radius-2: 2px;
  --font-family: 'Gotham-SSm';
  --screen-sm: #{$screen-sm};
  --screen-md: #{$screen-md};
  --screen-lg: #{$screen-lg};
  --screen-xl: #{$screen-xl};
}
