.App {
  position: relative;

  overflow-y: auto;
  display: flex;
  flex-direction: column;

  width: 100%;
  min-height: 100vh;
  margin: 0 auto;

  background-color: var(--color-base-dark-blue-light);
}
