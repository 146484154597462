.payment {
  overflow: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.payment-body {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  justify-content: center;

  width: 100%;

  text-align: center;

  &_mobile {
    padding: 16px;
  }
}

.top-slot {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  justify-content: center;
}
.header-text {
  text-align: center;
}
.top-slot-icon {
  margin: 20px 0;
}

.bottom-slot {
  display: flex;
  flex-direction: column;
  gap: 30px;
  align-items: center;
  justify-content: center;

  font-weight: (--font-weight-350);
}

.button-back {
  width: 285px;

  &_mobile {
    width: 100%;
  }
}
