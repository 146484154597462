.main-page {
  &-order {
    flex: 1;

    color: var(--color-white-100);
    &-showFullScreen{
      display: none;
    }
  }
}

.iframe-container {
  flex: 1;

  width: 100%;

  .iframe {
    width: 100%;

    border: none;
  }
}


