.button {
  cursor: pointer;

  display: flex;
  gap: 5px;
  align-items: center;
  justify-content: center;

  font-family: var(--font-family);
  font-weight: 350;
  color: var(--color-dark-100);
  letter-spacing: var(--letter-spacing-small);

  border-radius: var(--border-radius-3);

  &:disabled {
    cursor: default;
  }

  &_fullwidth {
    min-width: 100%;
  }

  &_standart {
    width: fit-content;
    padding: 7px 30px 8px;

    font-size: var(--font-size-15);
    line-height: var(--line-height-25);
    &_fill {
      background-color: var(--color-base-yellow-orange);
      border: none;
      &:focus {
        background-color: var(--color-base-yellow-orange);
        border-radius: 4px; // если прописать 2px, то закругления совсем не видно
        outline: 1px solid var(--color-white-100);
        outline-offset: -3px;
      }
      &:hover {
        background-color: var(--color-base-saffron);
      }
      &:active {
        background-color: var(--color-base-yellow-pressed);
      }
      &:disabled {
        color: var(--color-dark-50);

        background-color: var(--color-white-50);
      }
      &_danger {
        background-color: var(--color-base-alizarin-crimson);
      }
    }
    &_outline {
      color: var(--color-white-100);

      background-color: transparent;
      border: 1px solid var(--color-white-20);
      &:focus {
        border: 1px solid var(--color-base-yellow-orange);
      }
      &:hover {
        border: 1px solid var(--color-white-100);
      }
      &:active {
        border: 1px solid var(--color-white-80);
      }
      &:disabled {
        color: var(--color-white-20);

        border: 1px solid var(--color-white-20);
      }
      &_danger {
        border: 1px solid var(--color-base-alizarin-crimson);
      }

      &_method {
        padding: 20px;

        border-radius: 5px;
      }
    }

  }

  &_small {
    width: fit-content;
    padding: 5px 15px;

    font-size: var(--font-size-11);
    line-height: var(--line-height-15);
    &_fill {
      background-color: var(--color-base-yellow-orange);
      border: none;
      &:focus {
        background-color: var(--color-base-yellow-orange);
        border-radius: 4px;
        outline: 1px solid var(--color-white-100);
        outline-offset: -3px;
      }
      &:hover {
        background-color: var(--color-base-saffron);
      }
      &:active {
        background-color: var(--color-base-yellow-pressed);
      }
      &:disabled {
        color: var(--color-dark-50);

        background-color: var(--color-white-50);
      }
      &_danger {
        background-color: var(--color-base-alizarin-crimson);
      }
    }
    &_outline {
      color: var(--color-white-100);

      background-color: transparent;
      border: 1px solid var(--color-white-20);
      &:focus {
        border: 1px solid var(--color-base-yellow-orange);
      }
      &:hover {
        border: 1px solid var(--color-white-100);
      }
      &:active {
        border: 1px solid var(--color-white-80);
      }
      &:disabled {
        color: var(--color-white-20);

        border: 1px solid var(--color-white-20);
      }

      &_danger {
        border: 1px solid var(--color-base-alizarin-crimson);
      }
    }
  }
}

