.custom-tooltip {
  display: flex;
  align-items: center;
  justify-content: center;

  width: fit-content;
  height: fit-content;
  padding: 10px 6px;

  font-size: var(--font-size-11);
  color: var(--color-dark-50);

  background: var(--color-white-100);
  border-radius: var(--border-radius-5);

}

.tooltip-icon {
  cursor: pointer;
}
