.container {
  display: flex;
  flex-direction: column;
  gap: 36px;

  box-sizing: border-box;
  width: 100%;
  padding: 16px;

  background-color: hsl(0deg 0% 0% / 36.8%);
  border-radius: 10px;

  .positions-block {
    display: flex;
    flex-direction: column;
    gap: 48px;

    .position {
      display: flex;
      flex-direction: row;
      gap: 24px;

      width: 100%;

      &-left-block {
        max-width: 40px;
      }

      &-right-block {
        display: flex;
        flex-direction: column;
        gap: 10px;

        width: 100%;

        &-row {
          display: flex;
          flex-direction: row;

          &-title {
            margin: 0;

            font-size: 16px;
            color: white;
          }

          &-description {
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            margin: 0;

            font-size: 14px;

            &-title {
              color: rgb(86 86 86);
            }

            &-value {
              color: white;
            }
          }
        }
      }
    }
  }

  .total-block {
    display: flex;
    justify-content: space-between;

    margin-left: 64px;

    font-size: 16px;
  }
}
