.input {
  width: 100%;
  padding: 0;

  font-family: var(--font-family);
  font-size: var(--font-size-13);
  font-weight: (--font-weight-350);
  line-height: var(--line-height-20);
  color: var(--color-white-100);

  background-color: transparent;
  border: none;

  appearance: none;
  &:focus {
    outline: none;
  }

  &_disabled {
    pointer-events: none;

    color: var(--color-white-20);
  }

  &_upper-case-only {
    text-transform: uppercase;
  }

  &-wrapper {
    position: relative;

    display: flex;
    flex-direction: column;

    width: 100%;

    font-weight: var(--font-weight-350);

    &-description-ignore-size {
      padding-bottom: 20px;
    }
  }

  &-container {
    display: flex;
    flex-direction: row;
    gap: 10px;

    height: 20px;
    padding-top: 10px;
    padding-bottom: 10px;

    border-bottom: 1px solid var(--color-white-50);

    transition: 0.2s border-bottom;

    &:not(.input-container_disabled):hover,
    &:not(.input-container_disabled).input-container_hover,
    &:not(.input-container_disabled).input-container_not-empty,
    &:not(.input-container_disabled, .input-container_error, .input-container_success)[data-empty='false'] {
      border-bottom: 1px solid var(--color-white-100);
    }

    &_success {
      border-bottom: 1px solid var(--color-base-yellow-orange);
    }
    &_error {
      border-bottom: 1px solid var(--color-base-alizarin-crimson);
    }
    &_disabled {
      border-bottom: 1px solid var(--color-white-20);
    }
  }

  &-label {
    font-size: var(--font-size-13);
    color: var(--color-white-100);
    &_disabled {
      color: var(--color-white-20);
    }
  }

  &-description {
    padding-top: 5px;

    font-size: var(--font-size-11);
    line-height: var(--line-height-15);
    color: var(--color-white-50);
    &_success {
      color: var(--color-base-yellow-orange);
    }
    &_error {
      color: var(--color-base-alizarin-crimson);
    }
    &_disabled {
      color: var(--color-white-20);
    }

    &_ignore-parent-size {
      position: absolute;
      bottom: 0;
      left: 0;

      white-space: nowrap;
    }
  }
}

.left-icon {
  display: flex;
}

// для того, чтобы не менялся цвет фона при автозаполнении поля
input:-webkit-autofill {
  caret-color: var(--color-white-100);

  transition: background-color 5000s ease-in-out 0s;

  -webkit-text-fill-color: var(--color-white-100) !important;
}

input::placeholder {
  font-weight: (--font-weight-350);
  color: var(--color-white-50);
  text-transform: none;
}
